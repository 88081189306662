<template>
  <div class="component-intro">
    <div class="content">
      <img class="image" src="/assets/img/image.intro.svg" alt="Chracter for intro page" />
      <div class="introduce part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "도구 소개" : "Introduction" }}</div>
        <ul>
          <li>- {{ store.state.site.lang === "ko" ? "퍼블레싱은 명령어를 입력하여 웹 UI를 빠르게 그릴 수 있는 개발자 친화적인 도구입니다." : "Publessing is a developer-friendly tool that lets you quickly draw web pages by typing commands." }}</li>
          <li>- {{ store.state.site.lang === "ko" ? "이미 만들어진 수많은 부품들을 조립하여 웹 페이지를 빠르게 완성할 수 있습니다." : "You can quickly complete a web page by assembling numerous pre-made components." }}</li>
          <li>
            -
            {{
              store.state.site.lang === "ko"
                ? "완성된 결과물은 html, css, js 파일로 다운로드하여 사용하시는 프로그래밍 언어와 환경에 맞춰 활용하실 수 있습니다."
                : "The result can be downloaded as an html, css, js file and used according to the programming language and environment you are using."
            }}
          </li>
        </ul>
      </div>
      <div class="promotion part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "홍보 영상" : "Promotional video" }}</div>
        <div class="ratio-video">
          <iframe
            width="100%"
            height="100%"
            title="Video Player"
            src="https://www.youtube.com/embed/rIGYcAHEYXk"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="browser part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "브라우저 지원" : "Browser support" }}</div>
        <ul v-if="store.state.site.lang === 'ko'">
          <li>- 최신의 다양한 브라우저에서 프로그램을 사용하실 수 있습니다.</li>
          <li>- 그리고 대부분의 컴포넌트들은 아래의 브라우저를 지원합니다.</li>
          <li>- 특정 브라우저에서 문제점을 발견하신다면, <router-link :to="`/${store.state.site.lang}/community`">이메일</router-link>로 문의해주시기 바랍니다.</li>
        </ul>
        <ul v-else>
          <li>- You can use the program in a variety of modern browsers.</li>
          <li>- And most of the components support the following browsers.</li>
          <li>- If you encounter a problem with a specific browser, please contact us by <router-link :to="`/${store.state.site.lang}/community`">email</router-link>.</li>
        </ul>
        <div class="images">
          <img src="/assets/img/logo.chrome.svg" alt="Chrome" title="Chrome" />
          <img src="/assets/img/logo.safari.svg" alt="Safari" title="Safari" />
          <img src="/assets/img/logo.firefox.svg" alt="Firefox" title="Firefox" />
          <img src="/assets/img/logo.edge.svg" alt="Edge" title="Edge" />
          <img src="/assets/img/logo.ie.svg" alt="IE" title="IE" />
        </div>
      </div>
      <div class="responsive part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "반응형 웹 지원" : "Responsive UI Support" }}</div>
        <ul v-if="store.state.site.lang === 'ko'">
          <li>- 대부분의 컴포넌트들은 반응형 웹을 지원하여 데스크탑, 태블릿, 모바일 등 다양한 기기의 환경에서도 사용하실 수 있습니다.</li>
          <li>- 모바일 페이지만 필요한 경우, mobile 토글을 활성화하여 모바일용 컴포넌트를 사용하실 수 있습니다.</li>
        </ul>
        <ul v-else>
          <li>- Most of the components support the responsive web, so you can use it in the environment of various devices such as desktop, tablet, and mobile.</li>
          <li>- If you only need a mobile page, you can use the mobile component by activating the mobile toggle.</li>
        </ul>
        <!-- <div class="images">
          <img src="/assets/img/responsive-1622825_640.png" />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import defiens from "../defines.json";

export default {
  props: {
    visible: Boolean,
    callback: Function,
    load: Function,
  },
  setup(props) {
    const store = useStore();
    const lnbRef = ref(null);
    const nav = defiens.navs.find((n) => n.name === "intro");

    onMounted(() => {
      if (typeof props.load === "function") {
        props.load(lnbRef.value);
      }
    });

    return {
      store,
      lnbRef,
      nav,
    };
  },
};
</script>
<style lang="scss" scoped>
.component-intro {
  .part {
    ul {
      margin: 15px 0 0 0;
      padding: 0;
      list-style: none;

      li {
        font-size: 15px;
        margin-bottom: 7.5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.browser {
      .images {
        padding-top: 15px;
        margin-bottom: -15px;

        img {
          height: 70px;
          margin: 15px 30px 15px 0;
        }
      }
    }

    // &.responsive {
    //   .images img {
    //     margin-top: 15px;
    //   }
    // }
  }
}
</style>
